import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import gsap from "gsap";
import moment from "moment";
import { Modal, useModal } from "../components/anti/modal/modal";
import { ToastContainer, toast } from "react-toastify";

import Layout from "../components/layout";
import SEO from "../components/seo";
import DataTable from "react-data-table-component";

import { InputText } from "../components/anti/form/form";
import { Button } from "../components/anti/buttons/buttons";
import { NavTabs, TabPane, TabContent } from "../components/anti/tabs/tabs";
import { CoverContent } from "../components/anti/cover/cover";
import { Container, Section } from "../components/anti/grid/grid";
import { HeadingLink } from "../components/anti/type/type";

// Images
import imgBanner from "../assets/img/wedding/bg-header.png";
import icPeople from "../assets/img/wedding/ic-profile-white.png";
import imgBroadcast from "../assets/img/wedding/grfx-blank-broadcast.png";
import imgBlank from "../assets/img/wedding/grfx-blankstate-image.png";
import imgBlankGuest from "../assets/img/wedding/grfx-blank-guest.png";

const GET_ALL_TABLES = gql`
  query EventDetailsDashboard($id: ID!) {
    Event(id: $id) {
      id
      name
      startTime
      endTime
      cpw
      cpp
      eventDate
      location
      address
      link
      organizer {
        name
      }
      rsvps {
        title
        location
        date
        startTime
        endTime
        tables {
          id
          number
          capacity
          guests {
            name
            attendance
            status
            name
            phone
            attendance
            status
            phone
            updatedAt
            passcode
            category {
              name
            }
            answers {
              name
            }
            table {
              number
            }
          }
        }
      }
    }
  }
`;

const INVITATION = gql`
  mutation createInvitation($sendDate: String!) {
    createInvitation(
      rsvpId: 3
      name: "Broadcast Wedding Invitation"
      message: "Invitation Details"
      sendDate: $sendDate
    ) {
      id
      message
      sendDate
      rsvp {
        event {
          name
          eventDate
          startTime
          endTime
          address
          location
          link
        }
      }
    }
  }
`;

const DashboardDetails = ({ eventId }) => {
  const [showBtn, setShowBtn] = useState(false);
  // Animation
  useEffect(() => {
    // prettier-ignore
    gsap
      .timeline()
      // .from(".cover", 1, {autoAlpha: 0, ease: "expo.inOut", })
      .from(".cover-title span", 2, { y: 100, skewY: 7, ease: "expo.inOut", delay: -1, stagger: { amount: 0.2 }})
      .to(".cover-bg", 2, { scale: 1, ease: "expo.inOut", delay: -2 })
  }, []);

  // Modal
  const { isShowing, toggle } = useModal();

  const columns = [
    {
      name: "Nama",
      selector: "nama",
      sortable: true,
    },
    {
      name: "Passcode",
      selector: "passcode",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "No.Meja",
      selector: "numtable",
      sortable: true,
    },
    {
      name: "No.HP",
      selector: "handphonenum",
      sortable: true,
      grow: 2,
    },
    {
      name: "Kategori",
      selector: "category",
      sortable: true,
      grow: 2,
    },
    {
      name: "RSVP",
      selector: "rsvp",
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Jumlah",
      selector: "jumlah",
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Food Alergy",
      selector: "foodalergy",
      sortable: true,
    },
  ];

  const { loading, data, called, refetch } = useQuery(GET_ALL_TABLES, {
    variables: { id: eventId },
  });
  const [
    createInvitation,
    { data: invitationResponse, loading: invitationLoading },
  ] = useMutation(INVITATION);

  const details = data && data.Event;
  const eventGuests =
    details && details.rsvps.length > 0 && details.rsvps[0].tables;

  const eventDate = data && moment(details.eventDate).format("DD MMMM YYYY");
  const startTime = data && moment(details.startTime).format("LT");
  const endTime = data && moment(details.endTime).format("LT");
  const capacity =
    data &&
    details.rsvps.length > 0 &&
    details.rsvps[0].tables.reduce(
      (result, currentValue) => result + currentValue.capacity,
      0
    );
  const attendance =
    details &&
    details.rsvps.length > 0 &&
    details.rsvps[0].tables.reduce((result, currentValue) => {
      const total = currentValue.guests.reduce(
        (guestResult, guestCurrentValue) =>
          (guestCurrentValue.status === "CHECKIN" &&
            guestResult + guestCurrentValue.attendance) ||
          guestResult,
        0
      );
      return result + total;
    }, 0);

  const allGuests =
    eventGuests &&
    eventGuests.reduce((result, currentValue) => {
      const temp = currentValue.guests.reduce((currentResult, tableValues) => {
        currentResult.push(tableValues);
        return currentResult;
      }, []);
      result = [...result, ...temp];
      return result;
    }, []);

  const sendInvitation = () =>
    createInvitation({ variables: { sendDate: moment().toISOString() } })
      .then(() => {
        refetch();
        toast.success("Pesan telah terkirim");
      })
      .catch(() => toast.success("failed"));

  let totalAttendant = 0;

  details &&
    details.rsvps[0].tables.map((table) => {
      table.guests.map((guest) => {
        if (guest.status === "ATTEND" || guest.status === "CHECKIN") {
          totalAttendant += guest.attendance;
        }
      });
    });

  const coverBtnToggler = (e) => {
    if (e === "tab-3") {
      if (!showBtn) {
        setShowBtn(true);
      }
    } else {
      if (showBtn) {
        setShowBtn(false);
      }
    }
  };

  return (
    <>
      <ToastContainer hideProgressBar={true} autoClose={3000} />
      <Layout>
        <SEO title="Dashboard WO" />

        <CoverContent
          theme="dark"
          img={imgBanner}
          imgOverlay="20"
          className="mb-n2 cover-blue"
          contentMaxWidth="mw-100"
        >
          <div className="heading-wrapper">
            <div className="left-side">
              <div className="left-side-wrapper">
                <label>{details && details.organizer.name}</label>
                <p className="guest-attendance">
                  <img src={icPeople} className="ic-peopole" />
                  {`${attendance || 0}/${totalAttendant || 0} Hadir`}
                </p>
              </div>
              <h2 className="h2">{!loading && details && details.name}</h2>
            </div>
            {showBtn && (
              <div className="right-side">
                <Button
                  link="/dashboard-wo"
                  className="mr-3"
                  variant="outline-secondary"
                >
                  Preview
                </Button>
                <Button variant="primary" onClick={sendInvitation}>
                  {invitationLoading ? (
                    <i className="fas fa-spinner-third fa-spin" />
                  ) : (
                    "Kirim"
                  )}
                </Button>
              </div>
            )}
          </div>
        </CoverContent>

        <Section className="section-dashboard-wo">
          <Container>
            <NavTabs
              data={[
                { name: "Info Acara", target: "tab-1" },
                { name: "Daftar Tamu", target: "tab-2" },
                { name: "Form RSVP", target: "tab-3" },
                { name: "Broadcast", target: "tab-4" },
                // { name: "Atur Usher", target: "tab-5" },
              ]}
              onClick={coverBtnToggler}
            />
            <TabContent>
              <TabPane id="tab-1" className="active">
                <HeadingLink>
                  <h2 className="h3 mb-4"> Informasi Acara</h2>
                  {/* <Button
                  variant="primary"
                  className="btn"
                  link={`/admin/dashboard-wo/${eventId}/edit`}
                >
                  Edit Event
                </Button> */}
                </HeadingLink>
                <div className="card card-dashboard information">
                  <div className="card-body">
                    <div className="row mb-3">
                      <div className="col-6 col-md-6">
                        <div className="form-group">
                          <p className="label">PENGANTIN PRIA</p>
                          <p>{!loading && data && data.Event.cpp}</p>
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="form-group">
                          <p className="label">PENGANTIN Wanita</p>
                          <p>{!loading && data && data.Event.cpw}</p>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-6">
                        <div className="form-group">
                          <p className="label">TANGGAL/JAM RESEPSI</p>
                          <p>{eventDate}</p>
                          <p className="notes">
                            {startTime} - {endTime}
                          </p>
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="form-group">
                          <p className="label">Lokasi</p>
                          <p>{!loading && data && data.Event.location}</p>
                          <p className="notes">
                            {!loading && data && data.Event.address}
                          </p>
                          <Button
                            variant="link"
                            link={!loading && data && data.Event.link}
                            className={`${
                              data && data.Event.link == null ? "hidden" : ""
                            }`}
                          >
                            Lihat Peta
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="card card-dashboard information mt-4">
                <div className="card-body">
                  <div className="row">
                    <h2>Terima Angpao Digital</h2>
                  </div>
                </div>
              </div> */}
              </TabPane>
              <TabPane id="tab-2">
                <div>
                  <h2 className="h2 mb-4">Daftar Tamu</h2>

                  {allGuests && (
                    <DataTable
                      columns={columns}
                      ignoreRowClick={true}
                      data={
                        !loading &&
                        allGuests &&
                        allGuests.map((block, i) => {
                          return {
                            passcode:
                              block.passcode == null ? "-" : block.passcode,
                            numtable: block.table.number,
                            nama: block.name == null ? "-" : block.name,
                            status: block.status == null ? "-" : block.status,
                            handphonenum: (
                              <a
                                href={`tel:${block.phone}`}
                                className={`tel-link ${block.name}`}
                              >
                                {block.phone}
                              </a>
                            ),
                            category:
                              block.category == null
                                ? "-"
                                : block.category.name,
                            rsvp:
                              block.status === "UNSENT" ||
                              block.status === "NOTATTEND"
                                ? "NO"
                                : "YES",
                            jumlah:
                              block.attendance == null || block.attendance == 0
                                ? "-"
                                : block.attendance,
                            foodalergy:
                              block.answers.length > 0
                                ? block.answers[0].name
                                : "-",
                            // action: (<a onClick={formValidation} className="tel-link">Edit</a>),
                          };
                        })
                      }
                      responsive={true}
                      striped={true}
                      selectableRowsHighlight={true}
                      pagination
                    />
                  )}

                  {!allGuests && (
                    <div className="card card-dashboard information mt-4">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-6 col-md-6">
                            <img
                              src={imgBlankGuest}
                              className="img-fluid"
                            ></img>
                          </div>
                          <div className="col-6 col-md-6 content-center">
                            <div className="heading">
                              <h2 className="mb-0">Belum ada daftar tamu</h2>
                              {/* <p>
                            Upload desain undangan dalam format jpg. max. 1MB.
                            Desain undangan dikirimkan bersamaan dengan formulir
                            RSVP.
                          </p> */}
                              <a className="btn btn-primary">Upload Undangan</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <Modal isShowing={isShowing} hide={toggle}>
                  <h2 className="h6">Edit Profile</h2>

                  <div className="box mt-4">
                    <h2 className="h2">Andre</h2>
                    <div className="row mt-4">
                      <div className="col-6">
                        <div className="form-group">
                          <p className="mb-0">Passcode :</p>
                          <InputText
                            id="passcode"
                            placeholder="Masukkan Passcode/Name"
                            inputType="text"
                            value="2"
                            disabled="true"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <p className="mb-0">Status :</p>
                          <InputText
                            id="status"
                            placeholder="Status"
                            inputType="text"
                            value="Checkin"
                            disabled="true"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <p className="mb-0">Kategori :</p>
                          <InputText
                            id="kategori"
                            placeholder="Masukkan Kategori"
                            inputType="text"
                            value="VIP"
                            disabled="true"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <p className="mb-0">Kategori :</p>
                          <InputText
                            id="jumlah"
                            placeholder="Masukkan Jumlah Tamu"
                            inputType="text"
                            value="2"
                            disabled="true"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <p className="mb-0">No Hape :</p>
                          <InputText
                            id="kategori"
                            placeholder="Masukkan Nomer Handphone"
                            inputType="text"
                            value="081908062149"
                            disabled="true"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <p className="mb-0">Food Restriction :</p>
                          <InputText
                            id="food"
                            placeholder="Masukkan food restriction"
                            inputType="text"
                            value="Diet"
                            disabled="true"
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <p className="mb-0">label :</p>
                        <p>answer</p>
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary btn-block"
                    onClick={toggle}
                  >
                    Confirm
                  </button>
                </Modal>
              </TabPane>
              <TabPane id="tab-3">
                <div className="d-flex justify-content-between">
                  <h2 className="h2">Form RSVP</h2>
                  {/* <Button variant="primary">Simpan Perubahan</Button> */}
                </div>

                <div className="card card-dashboard information mt-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6 col-md-6">
                        <img src={imgBlank} className="img-fluid"></img>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="heading">
                          <h2>Belum ada desain undangan</h2>
                          <p>
                            Upload desain undangan dalam format jpg. max. 1MB.
                            Desain undangan dikirimkan bersamaan dengan formulir
                            RSVP.
                          </p>
                          {/* <a className="btn btn-primary">Upload Undangan</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card card-dashboard information mt-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6 col-md-8">
                        <h2 className="h5 mb-0">
                          Tanggal berapa RSVP paling lambat diisi tamu?
                        </h2>
                      </div>
                      <div className="col-6 col-md-4">
                        <h2 className="h5 mb-0 text-muted">Pilih Tanggal</h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card card-dashboard information mt-4">
                  <div className="card-body">
                    <div className="form-group mw-md-75">
                      <div className="row">
                        <div className="col-12">
                          <p className="label mb-2">PERTANYAAN :</p>
                          <InputText
                            id="jumlah"
                            placeholder="Will you be attending?"
                            inputType="text"
                            disabled="true"
                            className="mb-3"
                          />
                        </div>
                      </div>
                      <div className="row mt">
                        <div className="col-12 col-md mb-md-0 mb-2">
                          <div className="radio-wrapper">
                            <label className="radio-button">
                              Wouldn’t miss it!
                              <input
                                type="radio"
                                name="attending"
                                className="mb-0"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="col-12 col-md mb-md-0 mb-2">
                          <div className="radio-wrapper">
                            <label className="radio-button">
                              Will celebrate in spirit!
                              <input
                                type="radio"
                                name="attending"
                                className="mb-0"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-dashboard information mt-4">
                  <div className="card-body">
                    <div className="form-group">
                      <p className="label mb-2">PERTANYAAN :</p>
                      <InputText
                        id="jumlah"
                        placeholder="Number of people attending?"
                        inputType="text"
                        disabled="true"
                        className="mb-4"
                        value="Number of people attending?"
                      />
                      <InputText
                        id="jumlah"
                        placeholder="Enter number of people including you"
                        inputType="text"
                        disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </TabPane>

              <TabPane id="tab-4">
                <h2 className="h2 mb-4">Broadcast</h2>
                <div className="card card-dashboard information mt-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-6 col-md-6">
                        <img src={imgBroadcast} className="img-fluid"></img>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="heading">
                          <h2>Tidak ada histori broadcast</h2>
                          <p>
                            Buat pesan & atur jadwal untuk mengirimkan pesan
                            secara otomatis
                          </p>
                          <Button
                            variant="primary"
                            link={`/admin/dashboard-wo/${eventId}/create-broadcast`}
                          >
                            Buat Pesan
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="card card-dashboard mt-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6 col-md-2">
                      <h2 className="h5 mb-0">Checked <br/>In</h2>
                    </div>
                    <div className="col-6 col-md-7">
                      <h2 className="h5 mb-0">Parking Area</h2>
                      <p>Dear all guests, There are limited parking available on-site, but street parking is available and an adjacent parking lot is also available by contact through a valet service</p>
                    </div>
                    <div className="col-6 col-md-3 text-right">
                      <Button className="btn btn-primary">Kirim Sekrang</Button>
                    </div>
                  </div>
                </div>
              </div> */}
              </TabPane>

              {/* <TabPane id="tab-5">
              <h2 className="h2 mb-4">Atur Usher</h2>
              <div className="card card-dashboard information mt-4">
                <div className="card-body">
                  <div className="row">
                    <div className="col-6 col-md-6">
                      <img src={imgBroadcast} className="img-fluid"></img>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="heading">
                        <h2>Tidak ada histori broadcast</h2>
                        <p>Buat pesan & atur jadwal untuk mengirimkan pesan secara otomatis</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane> */}
            </TabContent>
          </Container>
        </Section>
      </Layout>
    </>
  );
};

export default DashboardDetails;
