import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useQuery, gql } from "@apollo/client";
import gsap from "gsap";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Container, Section } from "../components/anti/grid/grid";

const GUEST = gql`
  query GetTable($id: ID!) {
    Table(id: $id) {
      capacity
      number
      guests {
        name
        status
        attendance
        phone
        category {
          name
        }
        answers {
          name
        }
      }
    }
  }
`;

const TableDetails = ({ tableId }) => {
  const { loading, data, refetch, called } = useQuery(GUEST, {
    variables: { id: tableId },
  });

  // Animation
  useEffect(() => {
    if (called) {
      refetch();
    }
    // prettier-ignore
    gsap
      .timeline()
      // .from(".cover", 1, {autoAlpha: 0, ease: "expo.inOut", })
      .from(".cover-title span", 2, { y: 100, skewY: 7, ease: "expo.inOut", delay: -1, stagger: { amount: 0.2 }})
      .to(".cover-bg", 2, { scale: 1, ease: "expo.inOut", delay: -2 })
  }, []);

  //table
  const columns = [
    {
      name: "Nama",
      selector: "nama",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "No.HP",
      selector: "handphonenum",
      sortable: true,
      grow: 2,
    },
    {
      name: "Kategori",
      selector: "category",
      sortable: true,
    },
    {
      name: "RSVP",
      selector: "rsvp",
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Jumlah",
      selector: "jumlah",
      sortable: true,
    },
    {
      name: "Food Alergy",
      selector: "foodalergy",
      sortable: true,
    },
  ];

  const capacity = data?.Table.guests.reduce((result, currentValue) => {
    if (currentValue.status === "ATTEND" || currentValue.status === "CHECKIN") {
      result += currentValue.attendance;
    }
    return result;
  }, 0);

  return (
    <Layout>
      <SEO title="Table Detail" />
      <Section className="section-table-management">
        <Container>
          <div className="heading-wrapper mb-5">
            <div className="left-side">
              <h2 className="h2">
                Meja {`${!loading && data && data.Table.number}`}
              </h2>
              <p>VVIP</p>
            </div>
            <div className="right-side">
              <div className="p-box bg-white">
                <p className="label">Checked In</p>
                <h2 className="h2">
                  {!loading &&
                    data &&
                    `${data.Table.guests.reduce(
                      (result, currentValue) =>
                        (currentValue.status === "CHECKIN" &&
                          result + currentValue.attendance) ||
                        result,
                      0
                    )}/${capacity}`}
                </h2>
              </div>
            </div>
          </div>

          <DataTable
            columns={columns}
            progressPending={loading}
            data={
              !loading &&
              data &&
              data.Table.guests.map((block, i) => {
                return {
                  nama: block.name === null ? "-" : block.name,
                  status: block.status === null ? "-" : block.status,
                  handphonenum: (
                    <a href={`tel:${block.phone}`}>{block.phone}</a>
                  ),
                  category: block.category === null ? "-" : block.category.name,
                  rsvp:
                    block.status === "UNSENT" || block.status === "NOTATTEND"
                      ? "NO"
                      : "YES",
                  jumlah: block.attendance === 0 ? "-" : block.attendance,
                  foodalergy:
                    block.answers.length === 0 ? "-" : block.answers[0].name,
                };
              })
            }
            responsive={true}
            striped={true}
            selectableRowsHighlight={true}
            pagination
          />
        </Container>
      </Section>
    </Layout>
  );
};

export default TableDetails;
