import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { withFormik, Field, Form } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
// import moment from "moment";
// import DatePicker from "react-datepicker";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Container, Section } from "../components/anti/grid/grid";

const CreateEvent = () => {
  const [formValues, setFormValues] = useState({
    title: "",
    cpp: "",
    cpw: "",
    location: "",
    date: "",
    startTime: "",
    endTime: "",
    address: "",
    link: "",
    organizer: "1",
  });

  const MUTATION_CREATEEVENT = gql`
    mutation CreateEvent(
      $name: String
      $cpw: String
      $cpp: String
      $code: String
      $eventDate: String
      $location: String
      $address: String
      $link: String
      $startTime: String
      $endTime: String
      $organizerId: ID!
    ) {
      createEvent(
        name: $name
        cpw: $cpw
        cpp: $cpp
        code: $code
        eventDate: $eventDate
        location: $location
        address: $address
        link: $link
        startTime: $startTime
        endTime: $endTime
        organizerId: $organizerId
      ) {
        name
      }
    }
  `;

  const [
    createEvent,
    { data: eventCreate, loading: mutationLoading },
  ] = useMutation(MUTATION_CREATEEVENT);

  const onChangeParameter = (selected, setFieldValue) =>
    setFieldValue("organizer", selected);

  const onDateChange = (selected, setFieldValue) =>
    setFieldValue("date", selected);

  const onStarTimeChange = (selected, setFieldValue) =>
    setFieldValue("startTime", selected);

  const onEndTimeChange = (selected, setFieldValue) =>
    setFieldValue("endTime", selected);

  const EventCreate = ({ touched, errors, setFieldValue, values }) => {
    return (
      <Form>
        <div className="row">
          <div className="col-md-6 mb-3">
            <p className="label">JUDUL ACARA</p>
            <Field
              name="title"
              className="form-control"
              placeholder="Contoh: John & Jane"
              type="text"
            />
            {touched.title && errors.title && (
              <div className="validation-error">{errors.title}</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            {/* <p className="label">Wedding Organizer</p> */}
            <label>Pilih Wedding Organizer</label>
            <ReactSelect
              className="form-select w-100"
              classNamePrefix="select"
              options={[
                { value: "1", label: "Wedding Organizer 1" },
                { value: "2", label: "Wedding Organizer 2" },
                { value: "3", label: "Wedding Organizer 3" },
                { value: "4", label: "Wedding Organizer 4" },
                { value: "5", label: "Wedding Organizer 5" },
                { value: "6", label: "Wedding Organizer 6" },
              ]}
              defaultValue={{
                value: "1",
                label: "Wedding Organizer 1",
              }}
              onChange={(value) => onChangeParameter(value, setFieldValue)}
            />
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Nama Pengantin Pria</p>
            <Field
              name="cpp"
              className="form-control"
              placeholder="Masukan nama pengantin pria"
              type="text"
            />
            {touched.cpp && errors.cpp && (
              <div className="validation-error">{errors.cpp}</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Nama Pengantin Wanita</p>
            <Field
              name="cpw"
              className="form-control"
              placeholder="Masukan nama pengantin wanita"
              type="text"
            />
            {touched.cpw && errors.cpw && (
              <div className="validation-error">{errors.cpw}</div>
            )}
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12">
            <h2 className="h4 mb-3">Detail Acara</h2>
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Tanggal</p>
            <DatePicker
              name="date"
              className="form-control"
              placeholderText="Pilih tanggal"
              wrapperClassName="w-100"
              selected={values.date && new Date(values.date)}
              onChange={(value) => onDateChange(value, setFieldValue)}
            />
            {touched.date && errors.date && (
              <div className="validation-error">{errors.date}</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Lokasi</p>
            <Field
              name="location"
              className="form-control"
              placeholder="Contoh: Hotel Mercure Alam Sutera"
              type="text"
            />
            {touched.location && errors.location && (
              <div className="validation-error">{errors.location}</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Alamat</p>
            <Field
              name="address"
              className="form-control"
              placeholder="Masukan alamat lengkap"
              type="text"
            />
            {touched.address && errors.address && (
              <div className="validation-error">{errors.address}</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Link Lokasi (Optional)</p>
            <Field
              name="link"
              className="form-control"
              placeholder="Salin dan tempel link dari google map"
              type="text"
            />
            {touched.link && errors.link && (
              <div className="validation-error">{errors.link}</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Mulai Jam</p>
            <DatePicker
              name="startTime"
              className="form-control"
              placeholderText="Misal: 10:00 WIB"
              wrapperClassName="w-100"
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              dateFormat="HH:mm"
              timeIntervals={15}
              selected={values.startTime && new Date(values.startTime)}
              onChange={(value) => onStarTimeChange(value, setFieldValue)}
            />
            {touched.startTime && errors.startTime && (
              <div className="validation-error">{errors.startTime}</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Selesai Jam</p>
            <DatePicker
              name="endTime"
              className="form-control"
              placeholderText="Misal: 10:00 WIB"
              wrapperClassName="w-100"
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              dateFormat="HH:mm"
              timeIntervals={15}
              selected={values.endTime && new Date(values.endTime)}
              onChange={(value) => onEndTimeChange(value, setFieldValue)}
            />
            {touched.endTime && errors.endTime && (
              <div className="validation-error">{errors.endTime}</div>
            )}
          </div>
        </div>

        <div className="text-center mt-3">
          <button className="btn btn-primary">
            {mutationLoading ? (
              <i className="fas fa-spinner-third fa-spin" />
            ) : eventCreate ? (
              <i className="fal fa-check" />
            ) : (
              "Buat Acara"
            )}
          </button>
        </div>
      </Form>
    );
  };

  const MyEnhancedForm = withFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required!"),
      cpp: Yup.string().required("Name is required!"),
      cpw: Yup.string().required("Name is required!"),
      location: Yup.string().required("Location is required!"),
      date: Yup.string().required("Date is required!"),
      startTime: Yup.string().required("Start time is required!"),
      endTime: Yup.string().required("End time is required!"),
      address: Yup.string().required("Address is required!"),
    }),
    mapPropsToValues({
      title,
      cpp,
      cpw,
      date,
      location,
      address,
      link,
      startTime,
      endTime,
      organizer,
    }) {
      return {
        title: title || formValues.title,
        cpp: cpp || formValues.cpp,
        cpw: cpw || formValues.cpw,
        location: location || formValues.location,
        date: date || formValues.date,
        startTime: startTime || formValues.startTime,
        endTime: endTime || formValues.endTime,
        address: address || formValues.address,
        link: link || formValues.link,
        organizer: organizer || formValues.organizer,
      };
    },
    handleSubmit(values) {
      const {
        title,
        cpp,
        cpw,
        date,
        location,
        address,
        link,
        startTime,
        endTime,
        organizer,
      } = values;

      setFormValues(values);

      createEvent({
        variables: {
          name: title,
          cpw,
          cpp,
          code: "",
          eventDate: date,
          location,
          address,
          link,
          startTime,
          endTime,
          organizerId: organizer,
        },
      });
    },
  })(EventCreate);

  return (
    <Layout>
      <SEO title="Dashboard" />
      <Section className="section-table-management">
        <Container>
          <h2 className="h2 text-center mb-4">Buat Acara Baru</h2>
          <div className="card card-dashboard information">
            <div className="card-body">
              <MyEnhancedForm />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default CreateEvent;
