import React, { useState, useRef, cloneElement } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import BarcodeReader from "react-barcode-reader";

import Layout from "../components/layout";

import { withFormik, Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import ReactToPrint from "react-to-print";

import PdfDocument from "./document-template";
import { InputText, Select } from "../components/anti/form/form";
import { Modal, useModal } from "../components/anti/modal/modal";
import { Container, Section } from "../components/anti/grid/grid";

// const MyEnhancedForm = formikEnhancer(MyForm);

const Form = (props) => {
  const { values, touched, handleChange, handleBlur, handleSubmit } = props;

  const GET_ALL_TABLES = gql`
    query allTables {
      allGuests {
        name
        phone
        attendance
        status
        phone
        updatedAt
        passcode
        code
        category {
          name
        }
        answers {
          name
        }
        table {
          number
        }
      }
    }
  `;

  const MUTATION_CHECKIN = gql`
    mutation checkin($passcode: String!) {
      checkIn(passcode: $passcode) {
        status
        name
      }
    }
  `;

  // QR Code
  const [result, setResult] = useState("");
  const [errorQr, setErrorQr] = useState(false);

  const handleScan = (data) => {
    // setResult(data);
    console.log(data);
    // toggle();
  };

  const handleError = (err) => {
    console.error(err);
  };

  // const strapi = dataQuery.strapi.guests;
  // Modal
  const { isShowing, toggle } = useModal();
  const [error, setError] = useState("");
  const [validatedError, setValidatedError] = useState(false);
  const [value, setValue] = useState({
    name: "",
    attendance: "",
    date: "",
    numTable: "",
    passcode: "",
    code: "",
  });

  const { loading, data } = useQuery(GET_ALL_TABLES);
  // const checkin = !loading && data && data.allGuests;

  const [
    updateStatus,
    { data: guestStatus, loading: mutationLoading },
  ] = useMutation(MUTATION_CHECKIN);

  const formValidation = (values) => {
    // setError("false");
    data &&
      data.allGuests.map((guestlist, i) => {
        const now = moment(guestlist.updatedAt).format("LT");
        if (
          guestlist.passcode === values.passcode.value ||
          guestlist.name === values.passcode.value
        ) {
          setValue({
            ...value,
            name: guestlist.name,
            attendance: guestlist.attendance,
            date: now,
            numTable: guestlist.table.number,
            code: guestlist.code,
            passcode: guestlist.passcode,
          });
          setError("false");
          updateStatus({
            variables: {
              passcode: guestlist.passcode,
            },
          })
            .then((res) => {
              toggle();
            })
            .catch((err) => console.log(err));
        }
        if (
          guestlist.passcode === values.passcode.value ||
          (guestlist.name === values.passcode.value &&
            guestlist.status == "CHECKIN")
        ) {
          setValidatedError(true);
          setTimeout(() => toggle(), 1000);
        }
        if (
          guestlist.name !== values.passcode ||
          (guestlist.passcode !== values.passcode && error == false)
        ) {
          setError(true);
        }
      });
  };
  const reset = () => {
    setError("");
  };

  const componentRef = useRef();

  const fitToSelect2 = () => {
    return (
      data &&
      data.allGuests.map((guest, i) => {
        const { name } = guest;
        return {
          value: name,
          label: name,
        };
      })
    );
  };

  const onSubmit = (values) => {
    console.log(values);
  };

  const MyForm = () => {
    return (
      <Layout>
        <Section className="login-page usher">
          <Container className="mw-sm">
            <div className="login-box p-box text-center">
              <h2 className="text-center">Check-in Tamu</h2>
              <p>
                Minta tamu untuk menunjukkan passcode yang telah dikirimkan ke
                nomor whatsapp untuk melakukan check-in
              </p>
              <BarcodeReader onError={handleError} onScan={handleScan} />
              <p>{result}</p>
              <Formik
                onSubmit={(values) => formValidation(values)}
                initialValues={{
                  passcode: "",
                }}
                className="mt-4"
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  setFieldValue,
                  setFieldTouched,
                  values,
                  touched,
                  errors,
                  isInvalid,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3 text-left">
                      <Select
                        label="Passcode"
                        id="passcode"
                        name="passcode"
                        className="gray-10"
                        value={values.passcode}
                        onChange={setFieldValue}
                        onBlur={setFieldTouched}
                        error={errors.passcode}
                        touched={touched.passcode}
                        isSearchable={true}
                        options={fitToSelect2()}
                      />
                    </div>
                    <p
                      className={`text-danger text-center d-none ${error ==
                        "true" && "d-block"}`}
                    >
                      Passcode/Nama tidak ditemukan
                    </p>
                    {validatedError && (
                      <p className={`text-danger text-center`}>
                        Tamu sudah check-in
                      </p>
                    )}

                    <div>
                      <button
                        className="btn btn-primary btn-block"
                        onClick={handleSubmit}
                      >
                        {mutationLoading ? (
                          <i className="fas fa-spinner-third fa-spin" />
                        ) : (
                          "Check-In"
                        )}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </Container>
        </Section>
      </Layout>
    );
  };

  const CheckInForm = withFormik({
    validationSchema: Yup.object().shape({
      passcode: Yup.string().required("Passcode / Name is required!"),
    }),
    mapPropsToValues: (props) => ({
      passcode: props.passcode || "",
    }),
    handleSubmit(values) {
      console.log(values);
    },
  })(MyForm);

  return (
    <>
      <MyForm />
      <Modal
        isShowing={isShowing}
        hide={() => {
          toggle();
          reset();
        }}
      >
        <div className="modal-checkin">
          {/* <h2 className="h4">Check-in {value.name} Berhasil</h2> */}
          <h2 className="h4">{value.name} sudah Check-in</h2>
          <p className="notes">
            {value.attendance} orang tamu, checked in jam{" "}
            {moment().format("HH:mm")}.
            <br />
            Berikan nomor meja dan amplop hadiah kepada tamu.
          </p>
          <div className="p-box bg-success">
            <h2 className="h6">No. Meja </h2>
            <h2 className="h2">{value.numTable}</h2>
          </div>
          <div className="row">
            <div className="col">
              <div style={{ display: "none" }}>
                <div ref={componentRef}>
                  <PdfDocument title={value.name} code={value.code} />
                </div>
              </div>
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-outline-primary btn-block">
                    Print
                  </button>
                )}
                content={() => componentRef.current}
                documentTitle={value.name || "My Title"}
                documentPasscode={value.passcode || "Passcode"}
              />
            </div>
            <div className="col">
              <button
                className="btn btn-primary btn-block"
                onClick={() => {
                  toggle();
                  reset();
                }}
              >
                Selesai
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Form;
