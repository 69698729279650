import React, { useEffect, useState } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import gsap from "gsap";
import moment from "moment";

import Layout from "../components/layout";
import SEO from "../components/seo";
import DataTable from "react-data-table-component";
import { Container, Section } from "../components/anti/grid/grid";

// Images
import icDate from "../assets/img/wedding/ic-datetime.png";

const ALL_GUESTS = gql`
  query AllGuests($id: ID!) {
    Event(id: $id) {
      id
      name
      cpw
      cpp
      eventDate
      organizer {
        name
      }
      rsvps {
        title
        location
        date
        startTime
        endTime
        tables {
          id
          number
          capacity
          guests {
            name
            attendance
            status
            name
            phone
            attendance
            status
            phone
            updatedAt
            passcode
            category {
              name
            }
            answers {
              name
            }
            table {
              number
            }
          }
        }
      }
    }
  }
`;

const ListPage = ({ eventId }) => {
  const [lastUpdated, setLastUpdated] = useState({
    time: moment().format("DD MMMM YYYY, HH:mm"),
    loading: false,
  });

  const [getData, { loading, data, refetch, called }] = useLazyQuery(
    ALL_GUESTS
  );

  // Animation
  useEffect(() => {
    getData({ variables: { id: eventId } });
    // prettier-ignore
    gsap
      .timeline()
      // .from(".cover", 1, {autoAlpha: 0, ease: "expo.inOut", })
      .from(".cover-title span", 2, { y: 100, skewY: 7, ease: "expo.inOut", delay: -1, stagger: { amount: 0.2 }})
      .to(".cover-bg", 2, { scale: 1, ease: "expo.inOut", delay: -2 })
  }, []);

  // const event = !loading && data && data.event;
  let eventDate =
    !loading &&
    data &&
    data.Event &&
    data.Event.rsvps.length > 0 &&
    data.Event.rsvps[0].date;
  let date = eventDate && moment(eventDate).format("DD/MM/YYYY");
  let time = eventDate && moment(eventDate).format("HH:mm");
  let startDate = moment().format("DD/MM/YYYY");

  const update = () => {
    if (called) {
      refetch();
    }
    setLastUpdated({ ...lastUpdated, loading: true });
    setTimeout(() => {
      setLastUpdated({
        ...lastUpdated,
        time: moment().format("DD MMMM YYYY, HH:mm"),
        loading: false,
      });
    }, 1000);
  };

  const allGuests =
    data &&
    data.Event &&
    data.Event.rsvps.length > 0 &&
    data.Event.rsvps[0].tables.reduce((result, currentValue) => {
      const temp = currentValue.guests.reduce((currentResult, tableValues) => {
        currentResult.push(tableValues);
        return currentResult;
      }, []);
      result = [...result, ...temp];
      return result;
    }, []);

  const columns = [
    {
      name: "Nama",
      selector: "nama",
      sortable: true,
    },
    {
      name: "No.Meja",
      selector: "numtable",
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "No.HP",
      selector: "handphonenum",
      sortable: true,
      grow: 2,
    },
    {
      name: "Kategori",
      selector: "category",
      sortable: true,
    },
    {
      name: "RSVP",
      selector: "rsvp",
      sortable: true,
      maxWidth: "150px",
    },
    {
      name: "Jumlah",
      selector: "jumlah",
      sortable: true,
    },
    {
      name: "Food Alergy",
      selector: "foodalergy",
      sortable: true,
    },
    {
      name: "Action",
      selector: "action",
      sortable: true,
    },
  ];

  return (
    <Layout>
      <SEO title="Dashboard" />

      <Section className="section-table-management">
        <Container>
          <div className="heading-wrapper mb-4">
            <div className="left-side">
              <h2 className="h2">
                {!loading && data && data.Event && data.Event.name}
              </h2>
              <p className="date-detail">
                <img src={icDate} className="img-fluid" alt="icon date" />
                {`${
                  date && date === startDate
                    ? "Hari Ini"
                    : moment(eventDate).format("LL")
                } | ${time}`}
              </p>
              <p className="last-update">
                {`Terakhir diupdate pada ${lastUpdated.time}`}
                <i
                  className={`fas fa-redo ${
                    lastUpdated.loading || loading ? "fa-spin" : ""
                  }`}
                  onClick={update}
                />
              </p>
            </div>
          </div>

          <DataTable
            columns={columns}
            progressPending={loading}
            data={
              !loading &&
              allGuests &&
              allGuests.map((block, i) => {
                return {
                  nama: block.name == null ? "-" : block.name,
                  numtable: block.table.number,
                  status: block.status,
                  handphonenum: (
                    <a href={`tel:${block.phone}`}>{block.phone}</a>
                  ),
                  category: block.category.name,
                  rsvp: block.category.name === "RSVP" ? "Yes" : "No",
                  jumlah: block.attendance === 0 ? "-" : block.attendance,
                  foodalergy:
                    block.answers.length > 0 ? block.answers[0].name : "-",
                };
              })
            }
            responsive={true}
            striped={true}
            selectableRowsHighlight={true}
            pagination
          />
        </Container>
      </Section>
    </Layout>
  );
};

export default ListPage;
