import React, { useEffect } from "react";
import PropTypes from "prop-types";

// Nav Tabs
export const NavTabs = ({
  variant,
  data,
  scrollable,
  rightFade,
  className,
  wrapperClassName,
  onClick,
}) => {
  useEffect(() => {
    const tabs = document.querySelectorAll("[data-tab-target]");
    const tabContents = document.querySelectorAll("[data-tab-content]");
    const classRemove = (e) => {
      e.classList.remove("active");
    };

    tabs.forEach((tab) => {
      tab.addEventListener("click", () => {
        const target = document.querySelector(tab.dataset.tabTarget);

        if (target) {
          tabContents.forEach(classRemove);
          tabs.forEach(classRemove);
          tab.classList.add("active");
          target.classList.add("active");
        }
      });
    });

    return () => {
      tabs.forEach((tab) => {
        tab.removeEventListener("click", () => {
          const target = document.querySelector(tab.dataset.tabTarget);

          if (target) {
            tabContents.forEach(classRemove);
            tabs.forEach(classRemove);
            tab.classList.add("active");
            target.classList.add("active");
          }
        });
      });
    };
  }, []);

  const callback = (target) => {
    onClick && onClick(target);
  };

  return (
    <>
      <div
        className={`nav-${variant}-wrapper 
        ${scrollable && "nav-scrollable-wrapper"}
        ${rightFade && "scrollable-fade"}
        ${wrapperClassName} `}
      >
        <div className={`${scrollable && "nav-scrollable"}`}>
          <ul className={`nav nav-${variant} ${className}`} role="tablist">
            {data.map((item, i) => {
              return (
                <li
                  className="nav-item"
                  key={item.name}
                  onClick={() => callback(item.target)}
                >
                  <div
                    className={`nav-link ${i === 0 ? "active" : ""}`}
                    data-tab-target={`#${item.target}`}
                    role="tab"
                  >
                    {item.name}
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
NavTabs.propTypes = {
  variant: PropTypes.oneOf("tabs", "pills"), // Set nav tabs data
  data: PropTypes.any, // Set nav tabs data
  scrollable: PropTypes.string, // Set scrollable status
  rightFade: PropTypes.string, // Set scrollable status
  className: PropTypes.string, // Set nav tabs / pills optional classes
  wrapperClassName: PropTypes.string, // Set nav tabs / pills wrapper optional classes
};
NavTabs.defaultProps = {
  variant: "tabs",
  scrollable: "",
  scrollableFade: "",
  navClassName: "",
  className: "",
  wrapperClassName: "",
};

// Tab Content
export const TabContent = ({ children, className, id }) => {
  return (
    <div id={id} className={`tab-content ${className}`}>
      {children}
    </div>
  );
};
TabContent.propTypes = {
  className: PropTypes.string, // Set tab content optional classes
  children: PropTypes.any,
};
TabContent.defaultProps = {
  className: "",
};

// Tab Pane
export const TabPane = ({ id, children, className, onClick }) => {
  return (
    <div
      onClick={onClick}
      className={`tab-pane fade show ${className}`}
      id={id}
      role="tabpanel"
      aria-labelledby={id}
      data-tab-content
    >
      {children}
    </div>
  );
};
TabPane.propTypes = {
  className: PropTypes.string, // Set tab pane optional classes
  children: PropTypes.any,
};
TabPane.defaultProps = {
  className: "",
};

// Usage Example
//
//<NavTabs
//  data={[
//    { name: "Tab 1", target: "tab-1" },
//    { name: "Tab 2", target: "tab-2" },
//  ]}
// />
// <TabContent>
//  <TabPane id="tab-1" className="active">
//    Content 1
//  </TabPane>
//  <TabPane id="tab-1">
//    Content 2
//  </TabPane>
//</TabContent>
