import React, { useState, useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { useMutation, gql } from "@apollo/client";
import { Router } from "@reach/router";
import { withFormik, Form, Field } from "formik";
import * as Yup from "yup";

import { AuthContext } from "../../context/auth";
import { isLoggedIn } from "../../components/anti/utils/auth";

import SEO from "../../components/seo";

import PrivateRoute from "../../components/anti/utils/privateroute";

import { Container, Section } from "../../components/anti/grid/grid";
import Dashboard from "../../templates/dashboard";
import DashboardWo from "../../templates/dashboard-wo";
import DashboardUsher from "../../templates/dashboard-usher";
import DashboardDetails from "../../templates/dashboard-detail";
import EditEventPage from "../../templates/event-edit";
import TablePage from "../../templates/table";
import TableDetails from "../../templates/table-details";
import EventCreate from "../../templates/create-event";
import ListPage from "../../templates/guest-list";
import CreateBroadcast from "../../templates/broadcast";

// Images
import imgLogo from "../../assets/img/wedding/logo-invite-white.png";

const LoginPage = () => {
  const { info, setInfo } = useContext(AuthContext);
  const [formValues, setFormValues] = useState({ email: "", password: "" });

  isLoggedIn() && navigate("/admin/dashboard-wo");

  const LOGIN = gql`
    mutation Login($email: String!, $password: String!) {
      login(data: { email: $email, password: $password }) {
        accessToken
        refreshToken
        user {
          firstname
          lastname
          role
        }
      }
    }
  `;

  const [login, { loading, error: loginError }] = useMutation(LOGIN);

  const LoginForm = (props) => {
    const { touched, errors, handleSubmit } = props;

    return (
      <Section className="login-page">
        <Container className="mw-sm">
          <div className="text-center">
            <img src={imgLogo} className="img-fluid logo" alt="logo wedding" />
          </div>
          <div className="login-box p-box">
            <h2 className="text-center">Login to Dashboard</h2>
            <Form onSubmit={handleSubmit} className="mt-4">
              <div className="mb-3">
                <label>Email</label>
                <Field
                  name="email"
                  className="form-control"
                  placeholder="Input Email"
                  type="email"
                />
                {touched.email && errors.email && (
                  <div className="validation-error">{errors.email}</div>
                )}
              </div>

              <div className="mb-3">
                <label>Password</label>
                <Field
                  name="password"
                  className="form-control"
                  placeholder="Input Password"
                  type="password"
                />
                {touched.password && errors.password && (
                  <div className="validation-error">{errors.password}</div>
                )}
              </div>

              {loginError && loginError.graphQLErrors && (
                <p className={`text-danger text-center`}>
                  {loginError.graphQLErrors[0].message}
                </p>
              )}
              <div>
                <button className="btn btn-primary btn-block" type="submit">
                  {loading ? (
                    <i className="fas fa-spinner fa-spin"></i>
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </Form>
          </div>
        </Container>
      </Section>
    );
  };

  const FormValidated = withFormik({
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email()
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    mapPropsToValues({ email, password }) {
      return {
        email: email || formValues.email,
        password: password || formValues.password,
      };
    },
    handleSubmit(values) {
      setFormValues({
        ...formValues,
        email: values.email,
        password: values.password,
      });

      login({
        variables: { email: values.email, password: values.password },
      })
        .then((res) => {
          if (res.data) {
            setInfo({
              ...info,
              name: res.data.login.user.firstname,
              role: res.data.login.user.role,
              token: res.data.login.accessToken,
            });
            sessionStorage.setItem(
              "jwt",
              JSON.stringify({
                name: res.data.login.user.firstname,
                token: res.data.login.accessToken,
                role: res.data.login.user.role,
              })
            );
            return null;
          }
        })
        .then(() => navigate("/admin/dashboard-wo"))
        .catch((err) => console.log(err));
    },
  })(LoginForm);

  return (
    <>
      <SEO title="Checkin Guest" />
      <FormValidated />
    </>
  );
};

const Admin = () => {
  useEffect(() => {
    navigate("/admin/dashboard-wo");
  }, []);
  //prettier-ignore
  return (
    <Router>
      <PrivateRoute path="/admin/dashboard" component={Dashboard} />
      <PrivateRoute path="/admin/dashboard-wo" component={DashboardWo} />
      <PrivateRoute path="/admin/dashboard-usher" component={DashboardUsher} />
      <PrivateRoute path="/admin/dashboard-wo/:eventId" component={DashboardDetails} />
      <PrivateRoute path="/admin/dashboard-wo/:eventId/edit" component={EditEventPage} />
      <PrivateRoute path="/admin/dashboard-wo/create-event" component={EventCreate} />
      <PrivateRoute path="/admin/dashboard-wo/:eventId/tables" component={TablePage} />
      <PrivateRoute path="/admin/dashboard-wo/:eventId/tables/:tableId" component={TableDetails} />
      <PrivateRoute path="/admin/dashboard-wo/:eventId/create-broadcast" component={CreateBroadcast} />
      <PrivateRoute path="/admin/dashboard-wo/:eventId/tables/guest-list" component={ListPage} />
      <LoginPage path="/admin/login" />
    </Router>
  );
};

export default Admin;
