import React, { useEffect, useState } from "react";
import { useQuery, gql, useMutation } from "@apollo/client";
import gsap from "gsap";
import { withFormik, Field, Form } from "formik";
import * as Yup from "yup";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Container, Section } from "../components/anti/grid/grid";

const GET_EVENT_DETAILS = gql`
  query EventDetails($id: ID!) {
    Event(id: $id) {
      id
      name
      startTime
      endTime
      cpw
      cpp
      eventDate
      location
      address
      link
      organizer {
        name
        id
      }
    }
  }
`;

const EDIT_EVENT = gql`
  mutation EditEvent(
    $name: String
    $cpw: String
    $cpp: String
    $code: String
    $eventDate: String
    $location: String
    $address: String
    $link: String
    $startTime: String
    $endTime: String
    $organizerId: ID!
    $id: ID!
  ) {
    updateEvent(
      name: $name
      cpw: $cpw
      cpp: $cpp
      code: $code
      eventDate: $eventDate
      location: $location
      address: $address
      link: $link
      startTime: $startTime
      endTime: $endTime
      organizerId: $organizerId
      id: $id
    ) {
      name
    }
  }
`;

const EditEventPage = ({ eventId }) => {
  // Animation
  useEffect(() => {
    // prettier-ignore
    gsap
      .timeline()
      // .from(".cover", 1, {autoAlpha: 0, ease: "expo.inOut", })
      .from(".cover-title span", 2, { y: 100, skewY: 7, ease: "expo.inOut", delay: -1, stagger: { amount: 0.2 }})
      .to(".cover-bg", 2, { scale: 1, ease: "expo.inOut", delay: -2 })
  }, []);

  const { data, called, refetch } = useQuery(GET_EVENT_DETAILS, {
    variables: { id: eventId },
  });

  const [editEvent, { data: mutationData, loading }] = useMutation(EDIT_EVENT);

  const [formValues, setFormValues] = useState({
    name: "",
    cpp: "",
    cpw: "",
    location: "",
    eventDate: "",
    startTime: "",
    endTime: "",
    address: "",
    link: "",
    organizerId: "1",
  });

  const eventDetails = data && data.Event;

  const onChangeParameter = (selected, setFieldValue) =>
    setFieldValue("organizer", selected);

  const onDateChange = (selected, setFieldValue) =>
    setFieldValue("date", selected);

  const onStarTimeChange = (selected, setFieldValue) =>
    setFieldValue("startTime", selected);

  const onEndTimeChange = (selected, setFieldValue) =>
    setFieldValue("endTime", selected);

  const organizerOptions = [
    { value: "1", label: "Wedding Organizer 1" },
    { value: "2", label: "Wedding Organizer 2" },
    { value: "3", label: "Wedding Organizer 3" },
    { value: "4", label: "Wedding Organizer 4" },
    { value: "5", label: "Wedding Organizer 5" },
    { value: "6", label: "Wedding Organizer 6" },
  ];

  const EventForm = ({ touched, errors, setFieldValue, values }) => {
    return (
      <Form>
        <div className="card card-dashboard information">
          <div className="card-body">
            <div className="row">
              <h2 className="col-12 h2 mb-4">Informasi Acara</h2>
              <div className="col-6 col-md-6">
                <div className="form-group">
                  <p className="label mb-2">JUDUL ACARA</p>
                  <Field
                    name="name"
                    className="form-control"
                    placeholder="Masukan nama pengantin pria"
                    type="text"
                  />
                  {touched.name && errors.name && (
                    <div className="validation-error">{errors.name}</div>
                  )}
                </div>
                <div className="form-group">
                  <p className="label">Nama Pengantin Pria</p>
                  <Field
                    name="cpp"
                    className="form-control"
                    placeholder="Masukan nama pengantin pria"
                    type="text"
                  />
                  {touched.cpp && errors.cpp && (
                    <div className="validation-error">{errors.cpp}</div>
                  )}
                </div>
              </div>
              <div className="col-6 col-md-6">
                <div className="form-group">
                  <p className="label">Wedding Organizer</p>
                  <ReactSelect
                    className="form-select w-100"
                    classNamePrefix="select"
                    options={
                      (eventDetails &&
                        eventDetails.organizer && {
                          value: eventDetails.organizer.name,
                          label: eventDetails.organizer.id,
                        }) ||
                      organizerOptions
                    }
                    defaultValue={{
                      value: eventDetails && eventDetails.organizer.id,
                      label: eventDetails && eventDetails.organizer.name,
                    }}
                    onChange={(value) =>
                      onChangeParameter(value, setFieldValue)
                    }
                  />
                </div>
                <div className="form-group">
                  <p className="label">Nama Pengantin Wanita</p>
                  <Field
                    name="cpw"
                    className="form-control"
                    placeholder="Masukan nama pengantin wanita"
                    type="text"
                  />
                  {touched.cpw && errors.cpw && (
                    <div className="validation-error">{errors.cpw}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card card-dashboard information">
          <div className="card-body">
            <div className="row">
              <h2 className="col-12 h2 mb-4">Detail Resepsi</h2>
              <div className="col-6 col-md-6">
                <div className="form-group">
                  <p className="label mb-2">TANGGAL</p>
                  <DatePicker
                    name="eventDate"
                    className="form-control"
                    placeholderText="Pilih tanggal"
                    wrapperClassName="w-100"
                    selected={values.eventDate && new Date(values.eventDate)}
                    onChange={(value) => onDateChange(value, setFieldValue)}
                  />
                  {touched.eventDate && errors.eventDate && (
                    <div className="validation-error">{errors.eventDate}</div>
                  )}
                </div>
                <div className="form-group">
                  <p className="label mb-2">Lokasi</p>
                  <Field
                    name="location"
                    className="form-control"
                    placeholder="Contoh: Hotel Mercure Alam Sutera"
                    type="text"
                  />
                  {touched.location && errors.location && (
                    <div className="validation-error">{errors.location}</div>
                  )}
                </div>
              </div>
              <div className="col-6 col-md-6">
                <div className="form-group">
                  <p className="label mb-2">Alamat</p>
                  <Field
                    name="address"
                    className="form-control"
                    placeholder="Masukan alamat lengkap"
                    type="text"
                  />
                  {touched.address && errors.address && (
                    <div className="validation-error">{errors.address}</div>
                  )}
                </div>
                <div className="form-group">
                  <p className="label">Link Lokasi (Optional)</p>
                  <Field
                    name="link"
                    className="form-control"
                    placeholder="Salin dan tempel link dari google map"
                    type="text"
                  />
                  {touched.link && errors.link && (
                    <div className="validation-error">{errors.link}</div>
                  )}
                </div>
              </div>
              <div className="col-6 col-md-6">
                <div className="form-group">
                  <p className="label mb-2">Mulai Jam</p>
                  <DatePicker
                    name="startTime"
                    className="form-control"
                    placeholderText="Misal: 10:00 WIB"
                    wrapperClassName="w-100"
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeIntervals={15}
                    selected={values.startTime && new Date(values.startTime)}
                    onChange={(value) => onStarTimeChange(value, setFieldValue)}
                  />
                  {touched.startTime && errors.startTime && (
                    <div className="validation-error">{errors.startTime}</div>
                  )}
                </div>
              </div>
              <div className="col-6 col-md-6">
                <div className="form-group">
                  <p className="label mb-2">Selesai Jam</p>
                  <DatePicker
                    name="endTime"
                    className="form-control"
                    placeholderText="Misal: 10:00 WIB"
                    wrapperClassName="w-100"
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Time"
                    dateFormat="HH:mm"
                    timeIntervals={15}
                    selected={values.endTime && new Date(values.endTime)}
                    onChange={(value) => onEndTimeChange(value, setFieldValue)}
                  />
                  {touched.endTime && errors.endTime && (
                    <div className="validation-error">{errors.endTime}</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="card card-dashboard information mt-4">
      <div className="card-body">
        <div className="row">
          <h2>Terima Angpao Digital</h2>
        </div>
      </div>
    </div> */}

        <div className="clearfix mt-4 text-center">
          <button type="submit" className="btn btn-primary">
            {loading ? (
              <i className="fas fa-spinner-third fa-spin" />
            ) : mutationData ? (
              <i className="fal fa-check" />
            ) : (
              "Kirim"
            )}
          </button>
        </div>
      </Form>
    );
  };

  const EventEditForm = withFormik({
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Title is required!"),
      cpp: Yup.string().required("Name is required!"),
      cpw: Yup.string().required("Name is required!"),
      location: Yup.string().required("Location is required!"),
      eventDate: Yup.string().required("Date is required!"),
      startTime: Yup.string().required("Start time is required!"),
      endTime: Yup.string().required("End time is required!"),
      address: Yup.string().required("Address is required!"),
    }),
    mapPropsToValues({
      name,
      cpp,
      cpw,
      eventDate,
      location,
      address,
      link,
      startTime,
      endTime,
      organizerId,
    }) {
      return {
        name: name || (eventDetails && eventDetails.name),
        cpp: cpp || (!data && formValues.cpp) || (data && eventDetails.cpp),
        cpw: cpw || (!data && formValues.cpw) || (data && eventDetails.cpw),
        location:
          location ||
          (!data && formValues.location) ||
          (data && eventDetails.location),
        eventDate:
          eventDate ||
          (!data && formValues.eventDate) ||
          (data && eventDetails.eventDate),
        startTime:
          startTime ||
          (!data && formValues.startTime) ||
          (data && eventDetails.startTime),
        endTime:
          endTime ||
          (!data && formValues.endTime) ||
          (data && eventDetails.endTime),
        address:
          address ||
          (!data && formValues.address) ||
          (data && eventDetails.address),
        link: link || (!data && formValues.link) || (data && eventDetails.link),
        organizerId:
          "1" ||
          (!data && formValues.organizerId) ||
          (data && eventDetails.organizerId),
        id: eventId,
      };
    },
    handleSubmit(values) {
      // console.log(values);
      editEvent({ variables: values })
        .then((res) => res && refetch())
        .catch((err) => console.log(err));
    },
  })(EventForm);

  console.log(mutationData);

  return (
    <Layout>
      <SEO title="Dashboard" />

      <Section className="section-table-management">
        <Container>
          <h2 className="h2 text-center mb-4">Edit Acara</h2>
          <EventEditForm />
        </Container>
      </Section>
    </Layout>
  );
};

export default EditEventPage;
