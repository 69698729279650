import React, { useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { withFormik, Field, Form } from "formik";
import * as Yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
// import moment from "moment";
// import DatePicker from "react-datepicker";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { Container, Section } from "../components/anti/grid/grid";

const CreateBroadcast = () => {
  const [formValues, setFormValues] = useState({
    title: "",
    eventDate: "",
    startTimeBrodcast: "",
    message: "",
  });

  const MUTATION_CREATEBROADCAST = gql`
    mutation cretebroadcast(
      $name: String!
      $rsvpId: ID
      $message: String!
      $sendDate: String
      $categoryId: ID
    ) {
      createBroadcast(
        name: $name
        rsvpId: $rsvpId
        message: $message
        categoryId: $categoryId
        sendDate: $sendDate
      ) {
        id
        message
        sendDate
        type
        status
      }
    }
  `;

  const [createBroadcast, { data, loading: mutationLoading }] = useMutation(
    MUTATION_CREATEBROADCAST
  );

  const onDateChange = (selected, setFieldValue) =>
    setFieldValue("eventDate", selected);

  const onStarTimeChange = (selected, setFieldValue) =>
    setFieldValue("startTimeBrodcast", selected);

  const BroadcastCreate = ({ touched, errors, setFieldValue, values }) => {
    return (
      <Form>
        <div className="row">
          <div className="col-md-12 mb-3">
            <p className="label">JUDUL</p>
            <Field
              name="title"
              className="form-control"
              placeholder="Contoh: D-Day Reminder"
              type="text"
            />
            {touched.title && errors.title && (
              <div className="validation-error">{errors.title}</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Tanggal</p>
            <DatePicker
              name="eventDate"
              className="form-control"
              placeholderText="Pilih tanggal"
              wrapperClassName="w-100"
              selected={values.eventDate && new Date(values.eventDate)}
              onChange={(value) => onDateChange(value, setFieldValue)}
            />
            {touched.eventDate && errors.eventDate && (
              <div className="validation-error">{errors.eventDate}</div>
            )}
          </div>
          <div className="col-md-6 mb-3">
            <p className="label">Mulai Jam</p>
            <DatePicker
              name="startTimeBrodcast"
              className="form-control"
              placeholderText="Misal: 10:00 WIB"
              wrapperClassName="w-100"
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Time"
              dateFormat="HH:mm"
              timeIntervals={15}
              selected={
                values.startTimeBrodcast && new Date(values.startTimeBrodcast)
              }
              onChange={(value) => onStarTimeChange(value, setFieldValue)}
            />
            {touched.startTimeBrodcast && errors.startTimeBrodcast && (
              <div className="validation-error">{errors.startTimeBrodcast}</div>
            )}
          </div>

          <div className="col-md-12 mb-3">
            <p className="label">Pesan</p>
            <Field
              as="textarea"
              name="message"
              className="form-control"
              placeholder="Tulis Pesan"
              type="text"
            />
            {touched.message && errors.message && (
              <div className="validation-error">{errors.message}</div>
            )}
          </div>
        </div>

        <div className="text-center mt-3">
          <button className="btn btn-primary" type="submit">
            {mutationLoading ? (
              <i className="fas fa-spinner-third fa-spin" />
            ) : data && data.createBroadcast ? (
              <i className="fal fa-check" />
            ) : (
              "Kirim Broadcast"
            )}
          </button>
        </div>
      </Form>
    );
  };

  const MyEnhancedForm = withFormik({
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required!"),
      message: Yup.string().required("Message is required!"),
      eventDate: Yup.string().required("Date is required!"),
      startTimeBrodcast: Yup.string().required("Start time is required!"),
    }),
    mapPropsToValues({ title, message, eventDate, startTimeBrodcast }) {
      return {
        title: title || formValues.title,
        message: message || formValues.message,
        eventDate: eventDate || formValues.eventDate,
        startTimeBrodcast: startTimeBrodcast || formValues.startTimeBrodcast,
      };
    },
    handleSubmit(values) {
      setFormValues(values);
      const time = moment(values.startTimeBrodcast).format("HH:mm:ss");
      const date = moment(values.eventDate).format("ll");
      const isoString = moment(`${date} ${time}`).toISOString();

      createBroadcast({
        variables: {
          name: values.title,
          message: values.message,
          sendDate: isoString,
          rsvpId: 1,
          categoryId: 1,
        },
      });
    },
  })(BroadcastCreate);

  return (
    <Layout>
      <SEO title="Dashboard" />
      <Section className="section-table-management">
        <Container>
          <h2 className="h2 text-center mb-4">Buat Broadcast Pesan</h2>
          <div className="card card-dashboard information">
            <div className="card-body">
              <MyEnhancedForm />
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default CreateBroadcast;
