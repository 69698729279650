import React, { useEffect, useState } from "react";
import { useQuery, gql } from "@apollo/client";
import gsap from "gsap";
import DataTable from "react-data-table-component";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { InputText } from "../components/anti/form/form";
import { CoverContent } from "../components/anti/cover/cover";
import { Container, Section } from "../components/anti/grid/grid";
import { Modal, useModal } from "../components/anti/modal/modal";

// Images
import imgBanner from "../assets/img/wedding/bg-header.png";

const DashboardPage = () => {
  // Animation
  useEffect(() => {
    // prettier-ignore
    gsap
      .timeline()
      // .from(".cover", 1, {autoAlpha: 0, ease: "expo.inOut", })
      .from(".cover-title span", 2, { y: 100, skewY: 7, ease: "expo.inOut", delay: -1, stagger: { amount: 0.2 }})
      .to(".cover-bg", 2, { scale: 1, ease: "expo.inOut", delay: -2 })
  }, []);

  // Modal
  const { isShowing, toggle } = useModal();

  //table
  const guestlist = [
    {
      id: 1,
      nama: "Alicia Widjaja",
      numtable: "-",
      handphonenum: "08123456789",
      category: "Teman Kuliah",
      rsvp: "-",
      jumlah: "-",
      foodalergy: "Udang",
    },
    {
      id: 2,
      nama: "Amy Adams",
      numtable: "1",
      handphonenum: "08123456789",
      category: "Teman Kuliah",
      rsvp: "-",
      jumlah: "-",
      foodalergy: "-",
    },
    {
      id: 3,
      nama: "Ann Smith",
      numtable: "10",
      handphonenum: "08123456789",
      category: "VVIP",
      rsvp: "-",
      jumlah: "2",
      foodalergy: "-",
    },
    {
      id: 4,
      nama: "Arya Wicaksono",
      numtable: "5",
      handphonenum: "08123456789",
      category: "VIP",
      rsvp: "-",
      jumlah: "2",
      foodalergy: "Vegetarian",
    },
    {
      id: 5,
      nama: "Alicia Widjaja",
      numtable: "1",
      handphonenum: "08123456789",
      category: "Teman Kuliah",
      rsvp: "1",
      jumlah: "-",
      foodalergy: "Telur & Seafood",
    },
    {
      id: 6,
      nama: "Amy Adams",
      numtable: "2",
      handphonenum: "08123456789",
      category: "Teman Kuliah",
      rsvp: "-",
      jumlah: "2",
      foodalergy: "-",
    },
    {
      id: 7,
      nama: "Ann Smith",
      numtable: "3",
      handphonenum: "08123456789",
      category: "VVIP",
      rsvp: "-",
      jumlah: "2",
      foodalergy: "-",
    },
    {
      id: 8,
      nama: "Arya Wicaksono",
      numtable: "-",
      handphonenum: "08123456789",
      category: "VIP",
      rsvp: "-",
      jumlah: "2",
      foodalergy: "Vegetarian",
    },
  ];
  const columns = [
    {
      name: "Nama",
      selector: "nama",
      sortable: true,
    },
    {
      name: "Passcode",
      selector: "passcode",
      sortable: true,
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
    },
    {
      name: "No.Meja",
      selector: "numtable",
      sortable: true,
    },
    {
      name: "No.HP",
      selector: "handphonenum",
      sortable: true,
    },
    {
      name: "Kategori",
      selector: "category",
      sortable: true,
    },
    {
      name: "RSVP",
      selector: "rsvp",
      sortable: true,
    },
    {
      name: "Jumlah",
      selector: "jumlah",
      sortable: true,
    },
    {
      name: "Food Alergy",
      selector: "foodalergy",
      sortable: true,
    },
    // {
    //   name: "Action",
    //   selector: "action",
    //   sortable: true,
    // },
  ];

  // Guest Attendance
  const guestt = [
    {
      guestDatang: 10,
      guestCapacity: 50,
    },
  ];

  const [error, setError] = useState("");
  const formValidation = () => {
    toggle();
  };
  const reset = () => {
    setError("");
  };

  const GET_EVENTS = gql`
    query Event($id: ID!) {
      event(id: $id) {
        name
        code
        content
        created_at
        updated_at
        organizer {
          name
        }
      }
      tables(sort: "number") {
        id
        number
        capacities
        rsvp {
          title
          date
          location
          start_time
          end_time
          response_before
          attendance
          event {
            name
            code
            content
            organizer {
              name
            }
          }
        }
      }
    }
  `;

  const GET_ALL_TABLES = gql`
    query allTables {
      allGuests {
        id
        name
        passcode
        whatsapp
        phone
        attendance
        phone
        status
        table {
          id
          number
        }
        category {
          id
          name
        }
        answers {
          id
          guest {
            id
          }
          guestId
          question {
            name
            answers {
              id
              name
            }
          }
        }
      }
    }
  `;

  // const GET_RSVPS = gql`
  //   query Table($id: ID!) {
  //     table(id: $id) {
  //       number
  //       capacities
  //       rsvp {
  //         attendance
  //         guests {
  //           name
  //           total
  //           phone
  //           answer {
  //             name
  //             question {
  //               name
  //             }
  //           }
  //           rsvp_guest {
  //             status
  //             attendance
  //           }
  //           category {
  //             name
  //           }
  //         }
  //       }
  //     }
  //   }
  // `;

  const { loading, data } = useQuery(GET_ALL_TABLES);
  const guest = !loading && data && data.allGuests;

  const [checkin, setCheckin] = useState("");
  const [capacity, setcapacity] = useState("");

  return (
    <Layout>
      <SEO title="Dashboard" />

      <CoverContent
        theme="dark"
        img={imgBanner}
        imgOverlay="20"
        className="mb-n2 cover-blue"
        contentMaxWidth="mw-100"
      >
        {/* {strapiEvent.map((block, idx) => {
          const now = moment(block.organizer.updated_at).format("DD MMM YYYY");
          return (
            <div key={idx}>
              <div className="heading-wrapper">
                <div className="left-side">
                  <div className="left-side-wrapper">
                    <label>
                      {block.content == null ? "The Wedding of" : block.content}
                    </label>
                    {guestt.map((guestt, i) => {
                      return (
                        <div key={i}>
                          <p className="guest-attendance">
                            <img src={icPeople} className="ic-peopole" />
                            {!loading &&
                              listTables &&
                              listTables.tables.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue.rsvp.attendance,
                                0
                              )}
                            /
                            {!loading &&
                              listTables &&
                              listTables.tables.reduce(
                                (accumulator, currentValue) =>
                                  accumulator + currentValue.capacities,
                                0
                              )}{" "}
                            Hadir
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  <h2 className="h2">{block.name}</h2>
                </div>
                <div className="right-side">
                  <p>
                    Update terbaru {now}, 11:00 oleh {block.organizer.name}
                  </p>
                </div>
              </div>
            </div>
          );
        })} */}
      </CoverContent>

      <Section className="section-dashboard">
        <Container>
          <DataTable
            columns={columns}
            ignoreRowClick={true}
            data={guestlist}
            ignoreRowClick={true}
            data={
              !loading &&
              data &&
              data.allGuests.map((block, i) => {
                // console.log(block.answers.question.answe);
                return {
                  passcode: block.passcode == null ? "-" : block.passcode,
                  numtable: block.table.number,
                  nama: block.name == null ? "-" : block.name,
                  status: block.status == null ? "-" : block.status,
                  handphonenum: (
                    <a
                      href={`tel:${block.phone}`}
                      className={`tel-link ${block.name} ${
                        block.whatsapp == true ? "wa-status" : "wa-notactive"
                      }`}
                    >
                      {block.phone}
                    </a>
                  ),
                  category: block.category == null ? "-" : block.category.name,
                  rsvp: block.rsvp == null ? "YES" : block.rsvp,
                  jumlah: block.attendance == null ? "-" : block.attendance,
                  foodalergy:
                    block.answers.name == null
                      ? "-"
                      : block.answers.question.name,
                  // action: (
                  //   <a onClick={formValidation} className="tel-link">
                  //     Edit
                  //   </a>
                  // ),
                };
              })
            }
            // data={data.strapi.guests.map((block, i) => {
            //   return {
            //     numtable:
            //       block.rsvp.rsvp_guest.table.number == null
            //         ? "-"
            //         : block.rsvp.rsvp_guest.table.number,
            //     nama: block.name == null ? "-" : block.name,
            //     status: block.status == null ? "Checkin" : block.status,
            //     handphonenum: (
            //       <a
            //         href={`tel:${block.phone}`}
            //         className={`tel-link ${block.name} ${
            //           block.whatsappActive == true
            //             ? "wa-status"
            //             : "wa-notactive"
            //         }`}
            //       >
            //         {block.phone}
            //       </a>
            //     ),
            //     category:
            //       block.category.name == null ? "-" : block.category.name,
            //     rsvp: (
            //       <span
            //         className={`${block.name} ${
            //           block.rsvp.status == "checkin"
            //             ? "text-danger"
            //             : "text-success"
            //         }`}
            //       >
            //         {block.rsvp.status == null ? "YES" : block.rsvp}
            //       </span>
            //     ),
            //     jumlah:
            //       block.rsvp.rsvp_guest.attendance == null
            //         ? "-"
            //         : block.rsvp.rsvp_guest.attendance,
            //     foodalergy: block.answer.name == null ? "-" : block.answer.name,
            //     action: (
            //       <a onClick={formValidation} className="tel-link">
            //         Edit
            //       </a>
            //     ),
            //   };
            // })}
            responsive={true}
            striped={true}
            selectableRowsHighlight={true}
            pagination
          />
        </Container>
      </Section>

      <Modal isShowing={isShowing} hide={toggle}>
        <h2 className="h6">Edit Profile</h2>

        <div className="box mt-4">
          <h2 className="h2">Andrew Kusno</h2>
          <div className="row mt-4">
            <div className="col-6">
              <div className="form-group">
                <p className="mb-0">Passcode :</p>
                <InputText
                  id="passcode"
                  placeholder="Masukkan Passcode/Name"
                  inputType="text"
                  value="2"
                  disabled="true"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <p className="mb-0">Status :</p>
                <InputText
                  id="status"
                  placeholder="Status"
                  inputType="text"
                  value="Checkin"
                  disabled="true"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <p className="mb-0">Kategori :</p>
                <InputText
                  id="kategori"
                  placeholder="Masukkan Kategori"
                  inputType="text"
                  value="VIP"
                  disabled="true"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <p className="mb-0">Kategori :</p>
                <InputText
                  id="jumlah"
                  placeholder="Masukkan Jumlah Tamu"
                  inputType="text"
                  value="2"
                  disabled="true"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <p className="mb-0">No Hape :</p>
                <InputText
                  id="kategori"
                  placeholder="Masukkan Nomer Handphone"
                  inputType="text"
                  value="081908062149"
                  disabled="true"
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <p className="mb-0">Food Restriction :</p>
                <InputText
                  id="food"
                  placeholder="Masukkan food restriction"
                  inputType="text"
                  value="Diet"
                  disabled="true"
                />
              </div>
            </div>
            <div className="col-6">
              <p className="mb-0">label :</p>
              <p>answer</p>
            </div>
          </div>
        </div>

        <button className="btn btn-primary btn-block" onClick={toggle}>
          Confirm
        </button>
      </Modal>
    </Layout>
  );
};

export default DashboardPage;
