import React from "react";
// Images
// import imgBackground from "../assets/img/wedding/print-frame.png";

const PdfDocument = ({ title, code }) => {
  return (
    <div>
      {/* <img src={imgBackground} className="img-fluid" style={{position: "fixed"}} alt="background"/> */}
      <div style={{ position: "fixed", top: "50%", left: "25%" }}>
        <h1>{title}</h1>
        <h1>{code}</h1>
      </div>
    </div>
  );
};

export default PdfDocument;
