import React, { useContext } from "react";
import { navigate } from "gatsby";

import { AuthContext } from "../../../context/auth";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const { info } = useContext(AuthContext);

  if (location.pathname !== "/admin/login" && !info.token) {
    navigate(`/admin/login`);
    return null;
  } else if (
    location.pathname !== "/admin/dashboard-usher" &&
    info.token &&
    info.role === "USHER"
  ) {
    navigate(`/admin/dashboard-usher`);
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
