import React, { useEffect } from "react";
import { useQuery, gql } from "@apollo/client";
import gsap from "gsap";
import moment from "moment";

import Layout from "../components/layout";
import SEO from "../components/seo";

// import DashboardDetails from "../../templates/dashboard-detail";
// import EventEdits from "../../templates/event-edit";

// import { InputText } from "../components/anti/form/form";

import { Button, ButtonGroup } from "../components/anti/buttons/buttons";
import { Container, Section } from "../components/anti/grid/grid";
// import { useModal } from "../anti/modal/modal";

// Images
import icDate from "../assets/img/wedding/ic-datetime.png";
import icPeople from "../assets/img/wedding/ic-people-attend.png";

const DashboardWOPage = () => {
  // Animation
  useEffect(() => {
    // prettier-ignore
    gsap
      .timeline()
      // .from(".cover", 1, {autoAlpha: 0, ease: "expo.inOut", })
      .from(".cover-title span", 2, { y: 100, skewY: 7, ease: "expo.inOut", delay: -1, stagger: { amount: 0.2 }})
      .to(".cover-bg", 2, { scale: 1, ease: "expo.inOut", delay: -2 })
  }, []);

  // // Graphql
  const GET_EVENTS = gql`
    query allEvent {
      allEvents {
        id
        name
        startTime
        endTime
        cpw
        cpp
        eventDate
        location
        address
        link
        organizer {
          name
        }
        rsvps {
          id
          date
          startTime
          tables {
            capacity
            guests {
              status
              attendance
            }
          }
        }
      }
    }
  `;

  const { loading, data } = useQuery(GET_EVENTS);

  const now = moment().format("DD/MM/YYYY");
  // const runningEvents =
  //   data &&
  //   data.allEvents.reduce(
  //     (result, currentValue) =>
  //       result +
  //       currentValue.rsvps.reduce(
  //         (rsvpResult, currentRsvp) =>
  //           (moment(currentRsvp.date).format("DD/MM/YYYY") === now &&
  //             rsvpResult + 1) ||
  //           rsvpResult,
  //         0
  //       ),
  //     0
  //   );

  return (
    <Layout>
      <SEO title="Dashboard" />

      <Section className="section-dashboard">
        <Container>
          {/* <div className="row mb-5">
            <div className="col-md-6">
              <form>
                <InputText
                  id="food"
                  placeholder="Cari nama atau wedding organizer"
                  inputType="text"
                  disabled="true"
                />
              </form>
            </div>
            <div className="col-md-6"></div>
          </div> */}
          <div
            className={`row ${
              data && data.allEvents.length === 0 ? "d-block" : "d-none"
            }`}
          >
            <h2 className="mb-3">Tidak ada Event</h2>
          </div>
          <div
            className={`${
              data && data.allEvents.length === 0 ? "d-none" : "d-block"
            }`}
          >
            <div className="row">
              {!loading &&
                data &&
                data.allEvents.map((event, i) => {
                  const now = moment(event.rsvps.startTime).format(
                    "DD MMMM YYYY"
                  );
                  const countAttendace =
                    event.rsvps.length > 0 &&
                    event.rsvps[0] &&
                    event.rsvps[0].tables.reduce(
                      (result, currentValue) =>
                        result +
                        currentValue.guests.reduce(
                          (guestResult, currentGuest) =>
                            (currentGuest.status === "CHECKIN" &&
                              guestResult + currentGuest.attendance) ||
                            guestResult,
                          0
                        ),
                      0
                    );

                  const totalCapacity =
                    event.rsvps.length > 0 &&
                    event.rsvps[0] &&
                    event.rsvps[0].tables.reduce(
                      (result, currentValue) => result + currentValue.capacity,
                      0
                    );

                  return (
                    <React.Fragment key={`${event.number}`}>
                      <div className="col-md-3">
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <p className="label">{event.organizer.name}</p>
                            <h2 className="h5 mb-0">{event.name}</h2>
                            <div className="divider"></div>
                            <p className="date-detail">
                              <img
                                src={icDate}
                                className="img-fluid"
                                alt="icon date"
                              />
                              {now == null
                                ? "belum dikirim"
                                : moment(event.eventDate).format(
                                    "DD MMMM YYYY"
                                  )}
                            </p>
                            <p
                              className={`date-detail ${
                                event.guestattendance == ""
                                  ? "belum-dikirim"
                                  : ""
                              }`}
                            >
                              <img
                                src={icPeople}
                                className="img-fluid"
                                alt="icon date"
                              />
                              <span>
                                {countAttendace === 0 || !countAttendace
                                  ? "0"
                                  : countAttendace}
                              </span>{" "}
                              {countAttendace === 0 || !countAttendace
                                ? ""
                                : "dari"}{" "}
                              {totalCapacity && totalCapacity}
                            </p>
                            <ButtonGroup
                              btnMargin="mx-0 mx-md-1"
                              stackedSmDown
                              className="mt-3"
                            >
                              <Button
                                variant="outline-primary"
                                link={`/admin/dashboard-wo/${event.id}/tables`}
                              >
                                D-Day
                              </Button>
                              <Button
                                variant="primary"
                                link={`/admin/dashboard-wo/${event.id}`}
                              >
                                Detail
                              </Button>
                            </ButtonGroup>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
            </div>
          </div>
        </Container>
      </Section>
    </Layout>
  );
};

export default DashboardWOPage;
