import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import { gql, useLazyQuery } from "@apollo/client";
import gsap from "gsap";
import { Router, Link } from "@reach/router";
import moment from "moment";

import Layout from "../components/layout";
import SEO from "../components/seo";

// import { Link } from "../../components/anti/utils/utils";
import { Container, Section } from "../components/anti/grid/grid";

// Images
import icDate from "../assets/img/wedding/ic-datetime.png";

const GET_TABLES = gql`
  query GetTables($id: ID!) {
    Event(id: $id) {
      name
      rsvps {
        date
        tables {
          id
          number
          capacity
          guests {
            attendance
            status
          }
        }
      }
    }
  }
`;

const TablePage = ({ eventId, uri }) => {
  const [lastUpdated, setLastUpdated] = useState({
    time: moment().format("DD MMMM YYYY, HH:mm"),
    loading: false,
  });

  const [
    getData,
    { loading, data: listTables, called, refetch },
  ] = useLazyQuery(GET_TABLES);

  // Animation
  useEffect(() => {
    getData({
      variables: {
        id: eventId,
      },
    });
    // prettier-ignore
    gsap
      .timeline()
      // .from(".cover", 1, {autoAlpha: 0, ease: "expo.inOut", })
      .from(".cover-title span", 2, { y: 100, skewY: 7, ease: "expo.inOut", delay: -1, stagger: { amount: 0.2 }})
      .to(".cover-bg", 2, { scale: 1, ease: "expo.inOut", delay: -2 })
  }, []);

  let eventDate =
    !loading &&
    listTables &&
    listTables.Event &&
    listTables.Event.rsvps.length > 0 &&
    listTables.Event.rsvps[0].date;

  let date = eventDate && moment(eventDate).format("DD/MM/YYYY");
  let time = eventDate && moment(eventDate).format("HH:mm");
  let startDate = moment().format("DD/MM/YYYY");

  const update = () => {
    setLastUpdated({ ...lastUpdated, loading: true });

    setTimeout(() => {
      if (called) {
        refetch();
      } else {
        getData({
          variables: {
            id: eventId,
          },
        });
      }
      setLastUpdated({
        ...lastUpdated,
        time: moment().format("DD MMMM YYYY, HH:mm"),
        loading: false,
      });
    }, 1000);
  };

  // console.log(listTables?.Event?.rsvps[0]?.tables);

  return (
    <Layout>
      <SEO title="Table" />
      <Section className="section-table-management">
        <Container>
          <div className="header-wrapper">
            <h2 className="h2">
              {!loading &&
                listTables &&
                listTables.Event &&
                listTables.Event.name}
            </h2>
            <p className="date-detail">
              <img src={icDate} className="img-fluid" alt="icon date" />
              {`${
                date && date === startDate
                  ? "Hari Ini"
                  : moment(eventDate).format("DD MMMM YYYY")
              } | ${time}`}
            </p>

            <div className="row mb-4">
              <div className="col-6 col-md-3">
                <div className="p-box bg-white">
                  <p className="label">Checked In</p>
                  <h2 className="h2 text-success">
                    {!loading &&
                      listTables &&
                      listTables.Event &&
                      listTables.Event.rsvps.length > 0 &&
                      listTables.Event.rsvps[0].tables.reduce(
                        (result, currentValue) => {
                          const total = currentValue.guests.reduce(
                            (guestResult, guestCurrentValue) =>
                              (guestCurrentValue.status === "CHECKIN" &&
                                guestResult + guestCurrentValue.attendance) ||
                              guestResult,
                            0
                          );
                          return result + total;
                        },
                        0
                      )}
                  </h2>
                </div>
              </div>
              <div className="col-6 col-md-3">
                <div className="p-box bg-white">
                  <p className="label">RSVP Yes</p>
                  <h2 className="h2">
                    {!loading &&
                      listTables &&
                      listTables.Event &&
                      listTables.Event.rsvps.length > 0 &&
                      listTables.Event.rsvps[0].tables.reduce(
                        (accumulator, currentValue) => {
                          const temp = currentValue.guests.reduce(
                            (guestAcc, currentGuest) => {
                              //prettier-ignore
                              if (currentGuest.status === "CHECKIN" || currentGuest.status === "ATTEND") {
                                guestAcc += currentGuest.attendance;
                              }
                              return guestAcc;
                            },
                            0
                          );
                          accumulator += temp;
                          return accumulator;
                        },
                        0
                      )}
                  </h2>
                </div>
              </div>
            </div>
            <p className="last-update">
              {`Terakhir diupdate pada ${lastUpdated.time}`}
              <i
                className={`fas fa-redo ${
                  lastUpdated.loading || loading ? "fa-spin" : ""
                }`}
                onClick={update}
              />
            </p>
          </div>

          <h2 className="h5 title-wrapper">Daftar Meja</h2>
          {!loading &&
            listTables &&
            listTables.Event &&
            listTables.Event.rsvps.length > 0 &&
            listTables.Event.rsvps[0].tables.map((item, i) => {
              return (
                <React.Fragment key={`${item.number}`}>
                  <Link to={`${uri}/${item.id}`} className="card card-table">
                    <div className="row row-0">
                      <div className="col-3 col-md-1 content-center left-side">
                        <div className="p-box text-center">
                          <h2 className="h2">{item.number}</h2>
                        </div>
                      </div>
                      <div className="col-9 col-md-5">
                        <div className="p-box">
                          <h2 className="h6">
                            {`${!loading &&
                              listTables &&
                              item.guests.reduce(
                                (result, currentValue) =>
                                  (currentValue.status === "CHECKIN" &&
                                    result + currentValue.attendance) ||
                                  result,
                                0
                              )} dari ${item.capacity} terisi`}
                          </h2>
                          {/* <p className="label">{tablemanagement.category}</p> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                </React.Fragment>
              );
            })}

          <Link to={`${uri}/guest-list`} className="btn btn-primary btn-block">
            Lihat Semua Tamu
          </Link>
        </Container>
      </Section>
    </Layout>
  );
};

export default TablePage;
